import moment from 'moment-timezone'
import { getArea } from './area-data'
import { batchCommit, issuesSnapshotListener } from './data-helpers'
import * as dataObjects from './dataObjects'
import {
    type AreaStruct,
    type CommentivityStruct,
    type CurrentUser,
    type IssueStruct,
    type UserStruct,
    getAreaObject,
    getMiniUserObject
} from './dataObjects'
import { type Image, downloadListOfImages, formatImagesToUpload, uploadListOfImages } from './files-storage-data'
import type { DocumentReference, Firebase, FirebaseFirestore, WriteBatch } from './firebase'
import type { AssignedUserInfo, CategoryStruct, IssueArea, IssueItem, TaskStruct } from './firestore-structs'
import * as helpers from './helpers'
import * as c from './txt-constants'
import type { Needed } from './type-utils'
import { increaseIssueCounter } from './user-data'

/* global Blob */
const POSITION = 335.5 // wtf is this?

export type CategorySelection = {
    [key: CategoryStruct['id']]: string[]
}

export interface IssueCreateParams {
    area: Pick<AreaStruct, 'key' | 'name' | 'group' | 'description' | 'address' | 'organizationKey'>
    currentUser: UserStruct
    name: string
    hashtags?: string[]
    dueDate?: number | null
    assignedTo?: AssignedUserInfo[] | null
    priority?: boolean
    images?: Blob[] | string[]
    issueNumber?: string
    key?: string
    tempPhoto?: string
    categories?: CategorySelection
}

type ConstructIssueAssigningFields = Pick<IssueStruct, 'assignedTo' | 'startDate' | 'status' | 'assignedToKeys'>

export type IssueRef = DocumentReference<IssueStruct>

export interface IssueUpdateRequiredParams {
    issueKey: string
    currentUser: UserStruct
}

export interface IssuesQueryFilters {
    statuses?: IssueStruct['status'][]
    areaKey?: string | null
}

function getGenericIssuesQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection<IssueStruct>('tasks').where('organizationKey', '==', organizationKey).where('type', '==', 'issue')
}

export function getFilteredIssuesQuery(
    firebase: Firebase | FirebaseFirestore,
    organizationKey: string,
    { statuses, areaKey }: IssuesQueryFilters
) {
    let query = getGenericIssuesQuery(firebase, organizationKey)

    if (areaKey) {
        query = query.where('areaKey', '==', areaKey)
    }

    if (statuses && statuses.length > 0) {
        query = query.where('status', 'in', statuses)
    }

    return query
}

export function getIssueQuery(firebase: Firebase | FirebaseFirestore, issueKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection<IssueStruct>('tasks').doc(issueKey)
}

export function onFilteredIssues(
    firebase: Firebase,
    organizationKey: string,
    issueStatuses?: IssueStruct['status'][],
    areaKey: string | null = null
) {
    return issuesSnapshotListener(f =>
        getFilteredIssuesQuery(f, organizationKey, {
            statuses: issueStatuses,
            areaKey
        })
    )(firebase)
}

export async function getIssue(firebase: Firebase, key: string) {
    try {
        const snapIssue = await firebase.firestore().collection<IssueStruct>('tasks').doc(key).get()
        const issue = snapIssue.data()
        if (issue) {
            issue.key = snapIssue.id
        }
        return issue
    } catch (error) {
        console.log('(IssueData getIssue) ', error)
        return null
    }
}

export function getCommentivitiesQuery(firebase: Firebase | FirebaseFirestore, issueKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection<IssueStruct>('tasks').doc(issueKey).collection<CommentivityStruct>('commentivities').orderBy('created', 'desc')
}

export async function updateImageItemText(
    firebase: Firebase,
    issueKey: string,
    itemKey: string,
    obj: { content: string },
    currentUser: UserStruct,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = getIssueQuery(firebase, issueKey)
    const issueUpdate = { lastItemKey: itemKey }
    const issueSnapshot = await issueRef.get()
    const issueData = issueSnapshot.data()

    if (!issueData || !issueData.items) {
        throw new Error('Issue data or items not found')
    }

    const updatedItems = issueData.items.map((item: IssueItem) =>
        item.key === itemKey ? { ...item, text: { content: obj.content, position: POSITION } } : item
    )

    batch.update(issueRef, { items: updatedItems })

    await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'changed text on photo to ' + obj.content, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data updateImageItemText', 'updating image item text'))
}

export function constructIssueNumber(initials: string, issueCounter: number) {
    return initials + '#' + issueCounter
}

export function constructIssueNameWithHashtags(name: string, hashtags: string[]) {
    return hashtags.length > 0 ? name + ' ' + helpers.hashTagsToString(hashtags) : name
}

export function constructIssueCreatedCommentivity(
    issueRef: IssueRef,
    currentUser: UserStruct
): { commentivitiesRef: DocumentReference<CommentivityStruct>; commentivityObject: CommentivityStruct } {
    const commentivitiesRef = issueRef.collection<CommentivityStruct>('commentivities').doc()
    const commentivityObject = dataObjects.getCommentivityObject<'action'>(
        commentivitiesRef.id,
        'action',
        'created the issue',
        true,
        currentUser
    )

    return { commentivitiesRef, commentivityObject }
}

export function constructIssueAssigningFields(
    currentUser: UserStruct,
    assignedTo?: AssignedUserInfo[] | null,
    dueDate?: number | null
): ConstructIssueAssigningFields {
    let assignedUsers = assignedTo || null

    if (dueDate && !assignedTo?.length) {
        assignedUsers = [dataObjects.getMiniUserObject(currentUser)]
    }

    const assignedToStr = assignedUsers?.map(user => user.name).join(', ') || null
    const isAssigned = !!assignedToStr

    const result: ConstructIssueAssigningFields = {
        assignedTo: assignedUsers ?? null,
        assignedToKeys: assignedUsers?.map(user => user.key) ?? null,
        startDate: isAssigned ? (dueDate ?? moment().startOf('day').valueOf()) : null,
        status: isAssigned ? c.TASK_ASSIGNED : c.TASK_OPEN
    }
    return result
}

export async function constructBaseIssue(
    firebase: Firebase,
    {
        key,
        area,
        currentUser,
        name,
        dueDate,
        priority,
        hashtags,
        assignedTo,
        issueNumber,
        images,
        categories
    }: IssueCreateParams & {
        key: string
    }
): Promise<IssueStruct> {
    const timestamp = moment().valueOf()
    const userIssueCounter = await increaseIssueCounter(firebase, currentUser)

    const issue: Omit<IssueStruct, 'assignedTo' | 'assignedToName' | 'assignedContacts' | 'startDate'> = {
        key,
        name: constructIssueNameWithHashtags(name, hashtags ?? []),
        completedDate: 0,
        area: dataObjects.getAreaObjectForIssue(area),
        created: timestamp,
        organizationKey: currentUser.organizationKey,
        areaKey: area.key,
        creator: getMiniUserObject(currentUser),
        issueNumber: issueNumber || constructIssueNumber(currentUser.initials, userIssueCounter),
        changerName: currentUser.name,
        lastModifiedBy: getMiniUserObject(currentUser),
        isImagesLoading: false,
        updated: timestamp,
        creatorName: currentUser.name,
        type: 'issue',
        priority: priority ?? false,
        status: c.TASK_OPEN,
        visible: true,
        lastItemKey: c.TEXT_ISSUE,
        items: [],
        lastThumbUrl: null,
        updates: {
            [currentUser.key]: timestamp
        },
        categories
    }

    return { ...issue, ...constructIssueAssigningFields(currentUser, assignedTo, dueDate) }
}

export function constructIssueImageItem(organizationKey: string, image: Image) {
    const { key, url, thumbUrl, name } = image
    const timestamp = moment().valueOf()
    const textContent = new Date(timestamp).toLocaleTimeString('en-GB', {
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric'
    })
    const IMAGE_EXTENSION = '.jpg'

    return {
        key,
        created: timestamp,
        url,
        fileName: name,
        thumbUrl: thumbUrl,
        mediaType: 'file',
        thumbExtension: IMAGE_EXTENSION,
        organizationKey,
        text: {
            content: textContent,
            position: POSITION
        },
        updated: timestamp,
        visible: true
    } as IssueItem
}

export function constructItemsUpdatedFields(lastItem: IssueItem, currentUser: UserStruct) {
    const timeStamp = moment().valueOf()

    return {
        lastThumbUrl: lastItem.thumbUrl,
        lastItemKey: lastItem.key,
        updated: timeStamp,
        changerName: currentUser.name,
        lastModifiedBy: getMiniUserObject(currentUser),
        isImagesLoading: false
    } as Pick<IssueStruct, 'lastItemKey' | 'updated' | 'lastModifiedBy' | 'changerName' | 'isImagesLoading' | 'lastThumbUrl'>
}

export const assignImageItemsToIssue = async (
    firebase: Firebase,
    params: {
        currentUser: UserStruct
    },
    images: Blob[] | string[]
) => {
    try {
        const isImagesAlreadyCompressed = images.some(image => typeof image === 'string') && images.length % 2 === 0
        const initialImages = isImagesAlreadyCompressed ? images.slice(0, images.length / 2) : images
        const initialCompressedImages = isImagesAlreadyCompressed ? images.slice(images.length / 2) : images
        const formattedImages = await formatImagesToUpload(initialImages, {
            currentUserKey: params.currentUser.key,
            extension: '.jpg'
        })
        const formattedCompressedImages = await formatImagesToUpload(
            initialCompressedImages,
            { currentUserKey: params.currentUser.key, extension: '_thumb.jpg' },
            true
        )

        const imagesMetadata = await uploadListOfImages(firebase, formattedImages)
        const compressedImagesMetadata = await uploadListOfImages(firebase, formattedCompressedImages)

        const allImagesUrls = await downloadListOfImages(firebase, [...imagesMetadata, ...compressedImagesMetadata])
        const compressedImagesUrls = allImagesUrls.slice(imagesMetadata.length)
        const imagesUrls = allImagesUrls.slice(0, imagesMetadata.length)

        const imageItems = imagesUrls.map((url, index) => {
            const metadata = imagesMetadata[index]
            const thumbUrl = compressedImagesUrls[index]
            const uuid = firebase.firestore().collection<IssueStruct>('tasks').doc().id

            const image = {
                ...metadata,
                thumbUrl,
                url,
                key: uuid
            }

            const imageItem = constructIssueImageItem(params.currentUser.organizationKey, image)

            return { imageItem }
        })

        return imageItems
    } catch (error: unknown) {
        console.error('(IssueData assignImageItemsToIssue) ', error)
        throw new Error('Error while assigning images to issue, please try again')
    }
}

export async function createIssue(firebase: Firebase, params: IssueCreateParams): Promise<IssueStruct> {
    const db = firebase.firestore()
    const batch = db.batch()
    const issueRef = params.key ? db.collection<IssueStruct>('tasks').doc(params.key) : db.collection<IssueStruct>('tasks').doc()

    let issue = await constructBaseIssue(firebase, { key: issueRef.id, ...params })
    const commentivity = constructIssueCreatedCommentivity(issueRef, params.currentUser)

    if (params.images && params.images.length > 0) {
        const items = await assignImageItemsToIssue(
            firebase,
            {
                currentUser: params.currentUser
            },
            params.images
        )

        issue = {
            ...issue,
            ...constructItemsUpdatedFields(items[items.length - 1].imageItem, params.currentUser),
            items: items.map(i => i.imageItem)
        }
    }

    if (params.tempPhoto) {
        issue.lastThumbUrl = params.tempPhoto
        issue.lastItemKey = 'image'
    }

    batch.set(issueRef, issue)
    batch.set(commentivity.commentivitiesRef, commentivity.commentivityObject)

    await batch.commit().catch(error => {
        console.error('(IssueData createIssue) ', error)
        throw new Error('Error while creating issue, please try again')
    })

    return { ...issue }
}

export async function touchIssueAfterImageUpload(
    firebase: Firebase,
    issueKey: string,
    items: IssueItem[],
    currentUser: UserStruct,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? firebase.firestore().batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)

    const issueUpdate: Partial<IssueStruct> = { items, ...constructItemsUpdatedFields(items[items.length - 1], currentUser) }
    batch.update(issueRef, issueUpdate)

    !externalBatch && (await batchCommit(batch, 'issue-data touchIssueAfterImageUpload', 'touching issue after image upload'))
}

export async function toggleIsImagesLoading(firebase: Firebase, issueKey: string, externalBatch?: WriteBatch) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)

    batch.update(issueRef, { isImagesLoading: true })

    !externalBatch && (await batchCommit(batch, 'issue-data toggleIsImagesLoading', 'toggling isImagesLoading'))
}

export async function updateIssue(
    firebase: Firebase | FirebaseFirestore,
    params: IssueUpdateRequiredParams,
    issueUpdate: Partial<IssueStruct>,
    commentivityText: string,
    externalBatch?: WriteBatch
) {
    const { currentUser, issueKey } = params
    const db = 'firestore' in firebase ? firebase.firestore() : firebase

    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)

    batch.update(issueRef, issueUpdate)
    await addCommentivityToIssue(firebase, issueKey, 'action', commentivityText, currentUser, false, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data updateIssue', 'updating issue'))

    return { key: issueRef.id, ...issueUpdate } as Partial<IssueStruct>
}

export async function setPriorityToIssue(
    firebase: Firebase | FirebaseFirestore,
    { issueKey, priority, currentUser }: IssueUpdateRequiredParams & { priority: boolean },
    externalBatch?: WriteBatch
) {
    const commentivityText = 'changed priority to ' + (priority ? 'high' : 'low')
    await updateIssue(firebase, { issueKey, currentUser }, { priority }, commentivityText, externalBatch)
    return priority
}

export async function updateIssueCategories(
    firebase: Firebase,
    { issueKey, currentUser }: IssueUpdateRequiredParams,
    currentOrg: dataObjects.OrgStruct,
    categorySelection: CategorySelection,
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()

    const selectedCategoriesString = Object.keys(categorySelection).reduce((acc, categoryId) => {
        const selectedItems = categorySelection[categoryId]
        const categoryGroupName = currentOrg.categories?.find(category => category.id === categoryId)?.name
        if (!categoryGroupName || selectedItems.length === 0) {
            return acc
        }
        return [...acc, `${categoryGroupName}: ${selectedItems.join(', ')}`]
    }, [] as string[])

    const commentivity =
        selectedCategoriesString.length > 0 ? 'changed categories to: ' + selectedCategoriesString.join(' | ') : 'removed all categories'

    await updateIssue(firebase, { issueKey, currentUser }, { categories: categorySelection, key: issueKey }, commentivity, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data updateIssueCategories', 'updating issue categories'))
}

export async function assignIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        assignedTo: AssignedUserInfo[]
        dueDate?: number | null
    },
    externalBatch?: WriteBatch
) {
    const { issueKey, currentUser, assignedTo, dueDate } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueChangedFields = constructIssueAssigningFields(currentUser, assignedTo, dueDate)

    const issue = { ...issueChangedFields, key: issueKey }
    await updateIssue(
        firebase,
        { issueKey, currentUser },
        issue,
        'assigned the issue to ' + (issueChangedFields.assignedTo ?? []).map(user => user.name).join(', '),
        batch
    )

    !externalBatch && (await batchCommit(batch, 'issue-data assignIssue', 'assigning issue'))

    return { issue }
}

export async function setDueDateToIssue(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        dueDate,
        assignedTo
    }: IssueUpdateRequiredParams & {
        dueDate: number | null
        assignedTo: Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[] | null
    },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const dueDateText = dueDate ? `changed due date to ${moment(dueDate).format('DD/MM/YYYY')}` : `removed due date`
    const issueAssigningField = constructIssueAssigningFields(currentUser, assignedTo, dueDate)

    issueAssigningField.startDate = dueDate === null ? null : issueAssigningField.startDate

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueAssigningField, dueDateText, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data setDueDateToIssue', 'setting due date to issue'))

    return { issue }
}

export async function completeIssue(firebase: Firebase, params: IssueUpdateRequiredParams, externalBatch?: WriteBatch) {
    const { issueKey, currentUser } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate = { status: c.ISSUE_COMPLETE } as const

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'completed the issue', batch)

    !externalBatch && (await batchCommit(batch, 'issue-data completeIssue', 'completing issue'))

    return issue
}

export async function uncompleteIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        assignedContacts: TaskStruct['assignedTo']
    },
    externalBatch?: WriteBatch
) {
    const { issueKey, currentUser, assignedContacts } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate: Partial<IssueStruct> = { status: assignedContacts?.length ? c.ISSUE_ASSIGNED : c.ISSUE_OPEN }

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'uncompleted the issue', batch)

    !externalBatch && (await batchCommit(batch, 'issue-data uncompleteIssue', `set status to ${issueUpdate.status}`))

    return issue
}

export async function deleteIssue(firebase: Firebase, { issueKey, currentUser }: IssueUpdateRequiredParams, externalBatch?: WriteBatch) {
    return await updateIssue(
        firebase,
        {
            issueKey,
            currentUser
        },
        { status: c.ISSUE_DELETED },
        'deleted the issue',
        externalBatch
    )
}

export async function removeDueDateFromIssue(
    firebase: Firebase,
    params: IssueUpdateRequiredParams & {
        area: IssueArea | AreaStruct
        assignedTo: Needed<Partial<UserStruct>, 'key' | 'name' | 'initials'>[] | null
        taskKey?: string | null
    },
    externalBatch?: WriteBatch
) {
    return await setDueDateToIssue(firebase, { ...params, dueDate: null }, externalBatch)
}

export async function changeIssueName(
    firebase: Firebase,
    { issueKey, currentUser, name }: IssueUpdateRequiredParams & { name: string },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const commentivityText = 'changed the name to ' + `"${name}"`

    const issue = await updateIssue(firebase, { issueKey, currentUser }, { name }, commentivityText, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data changeIssueName', 'changing issue name'))

    return { issue }
}

export async function unassignIssue(firebase: Firebase, params: IssueUpdateRequiredParams, externalBatch?: WriteBatch) {
    const { issueKey, currentUser } = params
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueUpdate = constructIssueAssigningFields(currentUser, null) as Partial<IssueStruct>
    delete issueUpdate.startDate

    const issue = await updateIssue(firebase, { issueKey, currentUser }, issueUpdate, 'unassigned the issue', batch)

    !externalBatch && (await batchCommit(batch, 'issue-data unassignIssue', 'unassigning issue'))

    return issue
}

export async function changeArea(
    firebase: Firebase | FirebaseFirestore,
    {
        currentUser,
        issueKey,
        ...rest
    }:
        | { currentUser: CurrentUser; issueKey: string; areaKey: string }
        | { currentUser?: CurrentUser; issueKey: string; area: IssueStruct['area'] }
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const batch = db.batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)
    const areaObject = 'areaKey' in rest ? getAreaObject(await getArea(db, rest.areaKey)) : rest.area

    const issueObject: Partial<IssueStruct> = {
        area: areaObject,
        areaKey: areaObject.key,
        updated: moment().valueOf(),
        lastModifiedBy: currentUser ? getMiniUserObject(currentUser) : { key: 'system', name: 'system', initials: 'SYS' }
    }

    batch.update(issueRef, issueObject)

    if (currentUser) {
        const commentivitiesRef = issueRef.collection<CommentivityStruct>('commentivities').doc()
        const commentivityObject = dataObjects.getCommentivityObject(commentivitiesRef.id, 'action', 'changed the area', false, currentUser)
        batch.set(commentivitiesRef, commentivityObject)
    }

    await batch.commit().catch(error => {
        console.error('(IssueData changeArea) ', error)
    })

    return issueObject
}

export async function addCommentivityToIssue(
    firebase: Firebase | FirebaseFirestore,
    issueKey: string,
    type: CommentivityStruct['type'],
    text: string,
    currentUser: UserStruct,
    notifyCreator = true,
    externalBatch?: WriteBatch
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const batch = externalBatch ?? db.batch()

    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)
    const commentivitiesRef = issueRef.collection<CommentivityStruct>('commentivities').doc()

    const commentivityObject = dataObjects.getCommentivityObject(commentivitiesRef.id, type, text, notifyCreator, currentUser)

    batch.set(commentivitiesRef, commentivityObject)
    batch.update(issueRef, dataObjects.touchIssueObject(currentUser))

    !externalBatch &&
        (await batch.commit().catch(error => {
            console.log('(IssueData addCommentivityToIssue) ', error)
        }))

    return commentivityObject.key
}

export async function returnIssueUpdatedFieldsAfterItemDeleted(
    firebase: Firebase,
    issueKey: string,
    itemKey: string,
    currentUser: UserStruct,
    isLastItem?: boolean
): Promise<Pick<IssueStruct, 'lastItemKey' | 'updated' | 'lastModifiedBy' | 'changerName' | 'isImagesLoading' | 'lastThumbUrl' | 'items'>> {
    const issueRef = await getIssueQuery(firebase, issueKey).get()
    const issue = issueRef.data()
    const issueItems = issue?.items ?? []
    const isLast = isLastItem !== undefined ? isLastItem : issueItems[issueItems.length - 1].key === itemKey && issueItems.length === 1
    if (isLast) {
        return {
            lastItemKey: 'text-issue',
            lastThumbUrl: null,
            items: [],
            changerName: currentUser.name,
            lastModifiedBy: getMiniUserObject(currentUser),
            isImagesLoading: false,
            updated: moment().valueOf()
        }
    } else {
        const filteredItems = issueItems
            .filter(item => item.key !== itemKey)
            .sort((a, b) => helpers.sortTimeStampDescending(a.updated, b.updated))
        const updatedFields = { items: filteredItems, ...constructItemsUpdatedFields(filteredItems[0], currentUser) }

        return updatedFields
    }
}

export async function deleteItemFromIssue(
    firebase: Firebase,
    { currentUser, issueKey, itemKey, isLastItem }: IssueUpdateRequiredParams & { itemKey: string; isLastItem?: boolean },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)
    const issueUpdate = await returnIssueUpdatedFieldsAfterItemDeleted(firebase, issueKey, itemKey, currentUser, isLastItem)
    issueUpdate && batch.update(issueRef, issueUpdate)
    await addCommentivityToIssue(firebase, issueKey, 'action', 'removed one photo', currentUser, false, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data removeItemFromIssue', 'removing item from issue'))

    return issueUpdate
}

export async function addImageItemsToIssue(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        images,
        currentItems
    }: IssueUpdateRequiredParams & { images: Blob[] | string[]; currentItems?: IssueStruct['items'] },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()
    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)
    const items = await assignImageItemsToIssue(firebase, { currentUser }, images)
    const issueUpdate: Partial<IssueStruct> =
        currentItems && currentItems.length > 0
            ? {
                  items: [...items.map(item => item.imageItem), ...currentItems],
                  ...constructItemsUpdatedFields(items[items.length - 1].imageItem, currentUser)
              }
            : {
                  items: [...items.map(item => item.imageItem)],
                  ...constructItemsUpdatedFields(items[items.length - 1].imageItem, currentUser)
              }
    const commentivityText = `added ${items.length} photo${items.length > 1 ? 's' : ''}`
    console.log(issueUpdate)
    batch.update(issueRef, issueUpdate)
    await addCommentivityToIssue(firebase, issueKey, 'action', commentivityText, currentUser, false, batch)

    !externalBatch && (await batchCommit(batch, 'issue-data addImageItemsToIssue', 'adding image items to issue'))

    return issueUpdate
}

export async function addCommentToIssue(
    firebase: Firebase,
    { issueKey, currentUser, text }: IssueUpdateRequiredParams & { text: string },
    externalBatch?: WriteBatch
) {
    return await addCommentivityToIssue(firebase, issueKey, 'comment', text, currentUser, false, externalBatch)
}

export async function deleteCommentivity(
    firebase: Firebase,
    { issueKey, commentivityKey, currentUser }: IssueUpdateRequiredParams & { commentivityKey: string },
    externalBatch?: WriteBatch
) {
    const db = firebase.firestore()
    const batch = externalBatch ?? db.batch()

    const issueRef = db.collection<IssueStruct>('tasks').doc(issueKey)
    const commentivitiesRef = issueRef.collection<CommentivityStruct>('commentivities').doc(commentivityKey)

    batch.update(commentivitiesRef, { visible: false })
    batch.update(issueRef, dataObjects.touchIssueObject(currentUser))

    !externalBatch && (await batchCommit(batch, 'issue-data deleteCommentivity', 'deleting commentivity'))

    return commentivitiesRef.id
}

export async function updateIssueHashtags(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        hashtags,
        currentName
    }: IssueUpdateRequiredParams & {
        hashtags: string[]
        currentName: IssueStruct['name']
    },
    externalBatch?: WriteBatch
) {
    const updatedName = constructIssueNameWithHashtags(helpers.cleanHashTags(currentName), hashtags)
    const currentHashtags = helpers.getHashTags(currentName)

    const addedHashtags = hashtags.filter(item => !currentHashtags.includes(item))
    const removedHashtags = currentHashtags.filter(item => !hashtags.includes(item))

    let commentivityText = ''

    if (addedHashtags.length > 0 && removedHashtags.length > 0) {
        commentivityText = `added hashtag(s) ${helpers.hashTagsToString(addedHashtags)}, removed hashtag(s) ${helpers.hashTagsToString(
            removedHashtags
        )}`
    } else if (addedHashtags.length > 0) {
        commentivityText = `added hashtag(s) ${helpers.hashTagsToString(addedHashtags)}`
    } else if (removedHashtags.length > 0) {
        commentivityText = `removed hashtag(s) ${helpers.hashTagsToString(removedHashtags)}`
    }

    await updateIssue(firebase, { issueKey, currentUser }, { name: updatedName }, commentivityText, externalBatch)

    return updatedName
}

export async function addHashtagToIssue(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        hashtag,
        currentName
    }: IssueUpdateRequiredParams & {
        hashtag: string
        currentName: IssueStruct['name']
    },
    externalBatch?: WriteBatch
) {
    const updatedName = `${currentName} ${hashtag}`.trim()

    const commentivityText = `added hashtag ${hashtag}`

    await updateIssue(firebase, { issueKey, currentUser }, { name: updatedName }, commentivityText, externalBatch)

    return updatedName
}

export async function removeHashtagFromIssue(
    firebase: Firebase,
    {
        issueKey,
        currentUser,
        hashtag,
        currentName
    }: IssueUpdateRequiredParams & {
        hashtag: string
        currentName: IssueStruct['name']
    },
    externalBatch?: WriteBatch
) {
    const updatedName = helpers.removeHashtag(currentName, hashtag)

    const commentivityText = `removed hashtag ${hashtag}`

    await updateIssue(firebase, { issueKey, currentUser }, { name: updatedName }, commentivityText, externalBatch)

    return updatedName
}
